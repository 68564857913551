@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/responsive';
@import '../styles/mixins.scss';
@import '../styles/z-index';

.MobileSidebar__dim {
  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index(sidebar-dim);
  width: 100%;
  height: 100%;
  background-color: rgba($odc-black, 0.7);
  visibility: hidden;
  transition: 0.5s left;

  &--ios {
    cursor: pointer;
  }

  &--visible {
    left: #{$mobile-menu-width};
    visibility: visible;
  }
}

.MobileSidebar {
  position: fixed;
  top: 0;
  left: -100%;
  z-index: z-index(sidebar);
  width: $mobile-menu-width;
  height: 100%;
  border-right: 1px solid $dark-blue;
  background-color: $odc-black;
  transition: 0.3s;

  li {
    list-style: none;
  }

  &--visible {
    top: 0;
    left: 0;
    display: block;
    overflow-y: auto;
    opacity: 1;
    visibility: visible;
  }

  &--close {
    opacity: 0;
    visibility: hidden;
  }
}

.MobileSidebar__linksMobile {
  margin: 0 auto;

  .CategoryList__root {
    display: block;

    li {
      height: 100%;
    }

    .Category {
      @include font('p1-medium');
      display: block;
      padding: 1.4rem 2.4rem;
      color: $gray-02;
      box-shadow: 0 1px 0 0 $dark-blue;
      text-align: left;
      text-decoration: none;

      &:last-child {
        margin-bottom: 0;
      }

      &--selected {
        background-color: $odc-red;
        color: $odc-white;
      }
    }
  }

  @include screen-lg-gte {
    display: none;
  }
}

.MobileSidebar__linksMobile__head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem 2.4rem;
  border-bottom: 1px solid $dark-blue;

  img {
    width: 13.3rem;
    outline: none;
  }
}

.MobileSidebar__closeButtonWrapper {
  padding: 1rem;
}

.MobileSidebar__closeButton {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;

  @include buttonTitleWrapper('/icons/close-grey.svg', 2.4);
}
