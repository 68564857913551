@import '../styles/variables';
@import '../styles/fonts';

.DynamicSearchResult__viewMoreButton {
  @include font('p1-regular');
  display: block;
  text-align: center;
  text-decoration: none;
  margin-top: 3.2rem;
  border: 1px solid $gray-01;
  border-radius: 4px;
  width: 100%;
  color: $gray-02;
  padding: 1.2rem 0;
  cursor: pointer;
}

.DynamicSearchResult__notFound {
  margin-top: 8rem;
  text-align: center;

  p {
    @include font('heading3');
    color: $odc-white;
  }

  span {
    @include font('p1-medium');
    color: $gray-02;
  }
}
