@import './styles/variables';
@import './styles/responsive';

.App {
  @include screen-sm-gt {
    min-height: 76.8rem;
  }

  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  height: 100%;

  .App__header {
    position: relative;
  }

  .App__content {
    width: 100%;
    flex: 1;

    &--separated {
      // OW-374: 추후 디자인 수정에 따라 border를 생성할 수 있다
      // border-left: 1px solid $dark-blue;
      // border-right: 1px solid $dark-blue;
    }
  }

  .App__topAd {
    margin: 0 auto;
    // OW-321: 런칭 시점에는 Top, Left, Right 광고 영역을 숨긴다
    display: none;
  }

  .App__leftAd {
    @include side-banner-view-width {
      display: block;
    }

    display: none;
    width: auto;
    position: absolute;
    left: -17.6rem;
    top: 7.3rem;
  }

  .App__rightAd {
    @include side-banner-view-width {
      display: block;
    }

    display: none;
    width: auto;
    position: absolute;
    right: -17.6rem;
    top: 7.3rem;
  }

  .App__contentWrapper {
    position: relative;
    max-width: $max;
    margin: 0 auto;
    width: 100%;
  }
}
