@import '../../styles/variables';
@import '../../styles/fonts';

.SearchResults {
  flex: 1;
  padding: 0 1.6rem;
}

.SearchResults__title {
  @include font('heading3');
  color: $odc-white;
  margin: 1.6rem 0;
}

.SearchResults__loading {
  margin-top: 4rem;
}

.SearchResults__searchKeyword {
  color: $odc-pink;
}

.SearchResults__emptyResult {
  text-align: center;
  flex: 1;
  margin-top: 16rem;

  p {
    @include font('heading3');
    color: $odc-white;
  }

  span {
    @include font('p1-medium');
    color: $gray-02;
  }
}

.SearchResults__button {
  @include font('p2-regular');
  width: 100%;
  margin-top: 8rem;
  border-radius: 0.8rem;
  background-color: $odc-black;
  color: $gray-02;
  padding: 1.4rem;
  cursor: pointer;
}

.SearchResults__bottomAd {
  margin: 4rem 0;
}
