@import '../styles/variables';
@import '../styles/fonts';

.SideContentItem {
  cursor: pointer;
  padding: 1.6rem 0;
  display: block;

  &:not(:last-child) {
    border-bottom: 1px solid $odc-black;
  }

  .SideContentItem__image {
    display: inline-block;
    vertical-align: top;
    width: 12.8rem;
    height: auto;
    border-radius: 0.4rem;
    border: 1px solid $odc-black;
    object-fit: cover;
  }

  .SideContentItem__content {
    display: inline-block;
    width: calc(100% - 15rem);
    vertical-align: top;
    margin-left: 0.8rem;
  }

  .SideContentItem__title {
    @include font('p1-medium');
    color: $gray-01;
  }

  .SideContentItem__meta {
    @include font('p3-regular');
    color: $gray-02;
  }
}
