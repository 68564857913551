// screen size
$small: 480px;
$medium: 768px;
$large: 1024px;
$extra-large: 1200px;
$max: 1520px;
$theater: 1280px;

$mobile-menu-width: 32rem;

// colors
$odc-black: #111212;
$odc-white: #f7f4f3;
$odc-red: #a72a35;
$red: #ed4327;
$odc-pink: #f15c62;
$black: #000000;
$white: #ffffff;
$gray-01: #d8d8d8;
$gray-02: #9b9b9b;
$gray-03: #6a6a6a;
$gray-04: #4a4a4a;
$purple: #8e5585;
$dark-purple: #5f3252;
$blue: #4a90e2;
$yellow: #f7bb3c;
$navy: #153861;
$dark-blue: #1e232a;
$green: #1cb88f;

$odx-white: #fbfbfc;
$darkgray60: #222a36;

// odx-design-system
$lightgray80: #a7b9c4;
$system-black-90: rgba(0, 0, 0, 0.9);
$gray-dark-80: #141a23;
