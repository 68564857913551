@import '../styles/variables';
@import '../styles/fonts';

.LogoutButton {
  display: block;
  cursor: pointer;
  outline: none;
  position: relative;

  &--isLoading {
    background-image: none;
  }
}

.LogoutButton__loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2.4rem;
}
