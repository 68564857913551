@import '../styles/variables';

.AdArea {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;

  .AdArea__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: $white;
  }
}
