$z-index-map: (
  'global-notification': 100,
  'global-notification-dim': 95,
  'general-notification': 90,
  'general-notification-dim': 85,
  'modal': 80,
  'overlay': 75,
  'sidebar': 70,
  'sidebar-dim': 65,
  'search-overlay': 60,
  'navbar-selector': 55,
  'navbar': 50,
);

@function z-index($key) {
  @return map-get($z-index-map, $key);
}
