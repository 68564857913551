@import '../styles/variables';

.Input {
  border: 1px solid rgba($gray-02, 0.5);
  outline: none;
  width: 100%;

  &--block {
    display: block;
    width: 100%;
  }
}
