@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Loading__indicator {
  animation: spin 1s ease-in-out infinite;
}
