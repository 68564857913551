@import '../../styles/responsive';

.SearchResultPage {
  margin-top: 7.2rem;

  .SearchResultPage__title {
    position: absolute;
    top: -1rem;
    font-size: 0;
  }

  .SearchResultPage__wrapper {
    @include screen-only-xs {
      width: 100%;
      flex-direction: column;
    }

    display: flex;
    flex-direction: row;
    flex: 1;
    min-height: 76.8rem;
  }
}
