@function getAppliedOpacityColor($color, $opacity) {
  @return rgba($color, $opacity);
}

/// 16기반의 rem value를 10기반의 rem으로 변환합니다.
/// odc-web 디자인시스템의 베이스 사이즈 변환을 순차적으로 하기위한 mixin입니다.
$source-base-size: 16px;
$target-base-size: 10px;

@function rem($value) {
  @return $value * $source-base-size / $target-base-size * 1rem;
}
