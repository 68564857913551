@import './mixins.scss';
@import './responsive';

%clearFix {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.clearFix {
  @extend %clearFix;
}

%comma {
  &:not(:last-child)::after {
    content: ',';
    position: absolute;
    right: -0.4rem;
    bottom: 0;
  }

  &:not(:first-child) {
    margin-left: 0.7rem;
  }
}

%backgroundTransition {
  background: center / cover no-repeat url('/images/thumbnail-placeholder.svg');
  // NOTE: Need to fix chrome 'shaking bug' and transition not applied to firefox.
  // transition: background-image .5s ease-in-out;
}

%homeCoverTransition {
  transition: background 0 ease-in 0.3s;
}

%itemGrid {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0.8rem;

    li {
      width: 25%;
      padding: 0 0.8rem;

      @include screen-sm-lt {
        @include episode-display-count(2);
      }

      @include screen-only-sm {
        @include episode-display-count(2);
      }

      @include screen-only-md {
        @include episode-display-count(3);
      }

      @include screen-only-lg {
        @include episode-display-count(3);
      }

      @include screen-only-xl {
        @include episode-display-count(4);
      }

      @include screen-only-mx {
        @include episode-display-count(4);
      }
    }
  }
}

%screen-reader-text {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.screen-reader-text {
  @extend %screen-reader-text;
}

%outline-style {
  &:focus {
    outline: 2px solid $blue;
  }
}
