@import '../styles/fonts';
@import '../styles/variables';

.CCLanguages {
  margin: 0.6rem 0;
}

.CCLanguages__cc {
  @include font('badge');
  border: 0.1rem solid $gray-04;
  border-radius: 1rem;
  color: $gray-02;
  line-height: 1rem;
  letter-spacing: normal;
  display: inline-block;
  padding: 0.4rem 0.6rem;

  &::before {
    content: 'CC';
    padding: 0.2rem 0.6rem;
    margin: 0 0.4rem 0 -0.6rem;
    font-weight: bold;
    background-color: $gray-04;
    color: $odc-black;
    border-radius: 1rem 0 0 1rem;
  }
}
