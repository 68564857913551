@import '../../styles/variables';

.SearchLink {
  display: inline-block;
  color: $odc-white;

  li {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 0.3rem;
    }
  }
}
