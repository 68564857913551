@import '../styles/variables';
@import '../styles/fonts';

.EpisodeSubInfo {
  @include font('p2-regular');
  color: $gray-02;

  &::after {
    content: ' ';
  }
}
