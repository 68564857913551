@import '../styles/responsive';
@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/mixins';

.CategoryList__root {
  display: none;

  li {
    height: 7.2rem;
  }

  .Category {
    @include font('heading5');
    display: block;
    width: 100%;
    height: 100%;
    margin-right: 1.6rem;
    padding: 2.6rem 0.8rem;
    color: $odc-white;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: rgba($odc-white, 0.7);
    }

    &--selected {
      border-bottom: 4px solid $odc-red;
      color: $odc-white;
    }

    &--notSelected {
      color: rgba($white, 0.4);
    }
  }

  .Category__home {
    display: none;

    @include screen-lg-lt {
      display: block;
    }
  }

  @include screen-lg-gte {
    position: inherit;
    top: auto;
    left: auto;
    z-index: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    order: 2;
    margin-left: 2.4rem;
  }
}

.CategoryList__leaf {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5.6rem;
  padding-left: 2rem;

  .Category {
    @include font('p1-medium');
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin-right: 1.6rem;
    padding: 1.6rem 0.8rem;
    color: $odc-white;
    text-align: center;
    text-decoration: none;
    opacity: 0.7;
    cursor: pointer;

    @include screen-lg-gte {
      &--selected {
        @include font('p1-bold');
        background-color: rgba($odc-white, 0.4);
        opacity: 1;
      }
    }

    @include screen-lg-lt {
      display: none;
    }
  }

  .Category__home {
    display: none;
  }

  @include screen-lg-gte {
    position: inherit;
    top: auto;
    left: auto;
    z-index: inherit;
    background-color: $dark-blue;
  }
}

.Category__parent {
  @include font('p1-bold');
  display: none;
  height: 100%;
  color: $gray-02;
  user-select: none;

  @include screen-lg-lt {
    display: flex;
    align-items: center;
  }

  @include screen-md-lt {
    @include font('heading5');
  }
}

.Category__select {
  @include font('p1-bold');
  position: relative;
  display: block;
  height: 100%;
  margin-left: 1rem;
  padding: 1.6rem 0.8rem;
  color: $odc-white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 40%;
    left: -1rem;
    width: 1.6rem;
    height: 1.6rem;
    background: center / cover no-repeat url('/icons/arrow-forward-grey.svg');
  }

  &::after {
    content: '';
    position: absolute;
    top: 35%;
    right: -1rem;
    width: 1.6rem;
    height: 1.6rem;
    background: center / cover no-repeat url('/icons/arrow-drop-down-white.svg');
  }

  &:hover {
    color: rgba($odc-white, 0.7);
  }

  @include screen-lg-gte {
    display: none;
  }

  @include screen-md-lt {
    @include font('heading5');
  }
}
