@import '../../styles/variables';
@import '../../styles/responsive';
@import '../../styles/fonts';

.SearchResultItem {
  background-color: transparent;
  box-shadow: 0 1px 0 0 $dark-blue;
  list-style: none;
  padding: 1.6rem 0;
  cursor: pointer;

  .SearchResultItem__wrapper {
    display: flex;
    flex-direction: row;
    text-decoration: none;

    @include screen-md-lt {
      display: block;
    }
  }

  .SearchResultItem__content {
    flex: 1;
  }

  .SearchResultItem__imageWrapper {
    width: 23.5rem;
    margin-right: 1.6rem;

    @include screen-md-lt {
      width: 16.4rem;
      display: inline-block;
      vertical-align: top;
    }
  }

  .SearchResultItem__title {
    @include font('heading3');
    color: $odc-white;

    @include screen-md-lt {
      display: none;
    }
  }

  .SearchResultItem__subTitle {
    @include font('p1-medium');
    color: $gray-02;

    @include screen-md-lt {
      display: none;
    }
  }

  .SearchResultItem__mobileTitleWrapper {
    display: none;

    @include screen-md-lt {
      display: inline-block;
      vertical-align: top;
    }
  }

  .SearchResultItem__mobileTitle {
    @include font('heading4');
    color: $odc-white;
  }

  .SearchResultItem__mobileSubTitle {
    @include font('p2-regular');
    color: $gray-02;
  }

  .SearchResultItem__episode {
    @include font('p2-regular');
    color: $gray-04;

    @include screen-md-lt {
      margin-top: 0.8rem;
    }
  }

  .SearchResultItem__synopsys {
    @include font('p2-regular');
    color: $gray-02;
    margin-top: 1.6rem;
    text-overflow: ellipsis;
    overflow: hidden;

    @include screen-md-lt {
      position: relative;
      max-height: 6.4rem;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 3.2rem;
        background-image: linear-gradient(to bottom, rgba($odc-black, 0), $odc-black);
      }
    }
  }
}
