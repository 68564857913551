@import '../styles/responsive';
@import '../styles/fonts';
@import '../styles/variables';

.SigninLink {
  @include screen-lg-lt {
    display: none;
  }
  @include font('p2-regular');
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid rgba($odc-white, 0.4);
  border-radius: 16px;
  padding: 0.8rem 0.9rem 0.8rem 3.5rem;
  background: left 0.9rem center/2rem 2rem no-repeat url('/icons/account.svg');
  margin-left: 2.4rem;

  &--isMobile {
    @include screen-lg-lt {
      @include font('heading5');
      display: block;
      padding: 1.6rem 2.4rem;
      border: 0;
      border-top: 1px solid rgba($gray-04, 0.3);
      border-bottom: 1px solid rgba($gray-04, 0.3);
      border-radius: 0;
      background: none;
      background-color: $odc-black;
      color: $gray-01;
      margin-left: 0;
    }

    &::before {
      display: none;
    }
  }
}
