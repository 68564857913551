@import '../styles/responsive';
@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/z-index';

$searchBarWidth-md-gt: 384px;
$searchBarWidth: 100%;

.SearchBar {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  animation-name: fade-in;
  animation-duration: 0.3s;
  overflow-y: hidden;
  z-index: z-index(search-overlay);

  @keyframes fade-in {
    from {
      background-color: inherit;
    }

    to {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  @keyframes fade-out {
    from {
      background-color: rgba(0, 0, 0, 0.7);
    }

    to {
      background-color: inherit;
    }
  }

  &--willClose {
    animation-name: fade-out;
    background-color: inherit;
  }
}

.SearchBar__content {
  width: $searchBarWidth;
  top: 0;
  height: 100%;
  position: absolute;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  box-shadow: -1px 0 0 0 $dark-blue;

  @include screen-md-gt {
    width: $searchBarWidth-md-gt;
    height: 100vh;
    animation-duration: 0.3s;
    @keyframes slide-search-bar-visible {
      from {
        right: -1000px;
      }

      to {
        right: 0;
      }
    }
    @keyframes slide-search-bar-hide {
      from {
        right: 0;
      }

      to {
        right: -1000px;
      }
    }

    &.SearchBar__content--visible {
      right: 0;
      animation-name: slide-search-bar-visible;
    }

    &.SearchBar__content--hide {
      right: -1000px;
      animation-name: slide-search-bar-hide;
    }
  }
}

.SearchBarInput__inputWrapper {
  display: block;
}

.SearchBar__inputWrapperIcon {
  padding: 20px;

  i {
    width: 40px;
  }
}

.SearchBar__searchItems {
  overflow-y: auto;
  padding: 1.6rem;
}

.SearchBar__searchKeywordEmpty {
  @include font('p1-regular');
  display: block;
  margin-top: 8rem;
  width: 100%;
  color: $gray-02;
  text-align: center;
}

.SearchBar__linkWrapper {
  @include screen-md-gt {
    width: $searchBarWidth-md-gt;
  }

  width: $searchBarWidth;
  position: fixed;
  bottom: 0;
  padding: 1.2rem 1.6rem;
  background-color: $odc-black;

  &::before {
    content: '';
    position: absolute;
    top: -4rem;
    left: 0;
    right: 0;
    width: 100%;
    height: 4rem;
    background-image: linear-gradient(to bottom, rgba(17, 18, 18, 0), $odc-black);
  }
}

.SearchBar__loadMoreLink {
  @include font('p1-regular');
  display: block;
  padding: 1.2rem 0;
  border: 1px solid $gray-01;
  border-radius: 4px;
  color: $gray-02;
  cursor: pointer;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
