@import '../styles/variables';
@import '../styles/responsive';
@import '../styles/utils';
@import '../styles/fonts';

.Footer {
  @include font('p2-regular');

  align-self: center;
  margin: 4rem 2.4rem;
  padding-top: 2.4rem;

  max-width: $max;
  width: calc(100% - 4.8rem);
  color: $gray-02;
  border-top: 1px solid $dark-blue;

  .Footer__snsLinks {
    margin-bottom: 2.4rem;
    li {
      display: inline-block;
      margin-right: 1.6rem;
      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  .Footer__documentLinks {
    color: $gray-01;
    margin-bottom: 4rem;

    .Footer__documentLink {
      display: inline-block;
      margin-right: 1.6rem;

      a {
        display: block;
        width: 100%;
        cursor: pointer;
        text-decoration: none;
      }
      @include screen-md-lt {
        display: block;
        height: 3.2rem;
        margin-right: 0;
        margin-top: 0.8rem;
        border-bottom: 1px solid $dark-blue;
      }
    }
  }

  .Footer__company {
    color: $gray-02;

    @include screen-md-lt {
      @include font('body-ex-min');
    }
  }
}
