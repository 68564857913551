@import '../../styles/variables';
@import '../../styles/responsive';
@import '../../styles/fonts';
@import '../../styles/figures';

$link-color: $blue;
%separator {
  &:first-child {
    margin-left: 1.7rem;
    position: relative;
  }

  &:first-child::before {
    position: absolute;
    color: $gray-02;
    content: '|';
    top: 0;
    left: -0.9rem;
  }
}

.ProgramMeta {
  @include font('p2-regular');
  color: $gray-02;
  margin-top: 1.6rem;

  a {
    color: $link-color;
    font-weight: normal;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @include screen-only-xs {
    margin-top: 0.4rem;
  }
}

.ProgramMeta__nation {
  @extend %separator;
}

.ProgramMeta__category,
.ProgramMeta__genre {
  @extend %separator;
}

.ProgramMeta__writerLinks {
  margin-left: 1.7rem;
  position: relative;
  &::before {
    position: absolute;
    color: $gray-02;
    content: '|';
    top: 0;
    left: -0.9rem;
  }
}
