@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/figures';

.DynamicSearchResultItem {
  border-bottom: 1px solid $odc-black;
  list-style: none;
  padding: 1.6rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    margin-bottom: 14rem;
    border-bottom: 0;
  }
}

.DynamicSearchResultItem__searchItemImage {
  width: 12.8rem;
  height: auto;
  display: inline-block;
  vertical-align: top;
  border: 1px solid $odc-black;
  border-radius: 0.4rem;
}

.DynamicSearchResultItem__searchItemContent {
  display: inline-block;
  vertical-align: top;
  margin-left: 0.8rem;
  width: calc(100% - 13.6rem);
}

.DynamicSearchResultItem__searchItemtitle {
  @include font('p1-medium');
  color: $gray-01;
}

.DynamicSearchResultItem__episodeName {
  @include font('p2-regular');
  color: $gray-02;
}

.DynamicSearchResultItem__createdAt {
  @include font('p2-regular');
  margin-top: 0.2rem;
  color: rgba($gray-02, 0.7);
}

.DynamicSearchResultItem__genre {
  @include font('p2-regular');
  color: rgba($gray-02, 0.7);
}

.DynamicSearchResultItem__nation {
  position: relative;
}
