@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/responsive';

$button-border-color: 1px solid rgba($gray-04, 0.5);
$link-border-color: 1px solid rgba($gray-02, 0.2);

.UserMenu {
  @include screen-lg-lt {
    display: none;
  }

  position: relative;
  display: inline-block;
  margin-left: 2.4rem;

  &--isMobile {
    @include screen-lg-lt {
      display: block;
      margin-left: 0;

      &::before {
        display: none;
      }
    }
  }
}

.UserMenu__button {
  @include screen-lg-lt {
    @include font('heading5');
    color: $gray-01;
    width: 100%;
    background-position: left 2.4rem center;
    text-align: left;
    padding: 1.8rem 4.8rem;
    border-radius: 0;

    &::after {
      content: '';
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      right: 2.4rem;
      top: 1.8rem;
      background: center / cover no-repeat url('/icons/ico-arrow-down-white.svg');
      transition: transform 0.3s;
    }

    &--active::after {
      transform: rotate(180deg);
    }
  }

  @include font('p2-regular');
  background: $navy left 0.9rem center/2rem 2rem no-repeat url('/icons/account.svg');
  border-radius: 16px;
  padding: 0.8rem 0.9rem 0.8rem 3.5rem;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
}

.UserMenu__links {
  @include screen-lg-lt {
    @include font('p2-regular');
    position: relative;
    display: block;
    color: $gray-01;
    background-color: $navy;
    margin-top: 0;
    width: 100%;
    border: none;
    border-radius: 0;
  }
  @include font('p1-regular');
  position: absolute;
  right: 0;
  margin-top: 0.8rem;
  border: 1px solid $gray-02;
  border-radius: 0.8rem;
  background-color: $odc-black;
  text-align: left;
  width: 25.6rem;
}

.UserMenu__link {
  @include screen-lg-lt {
    border-bottom: 0;
    &:first-child {
      border-top: $link-border-color;
    }
    &:not(:last-child) {
      border-bottom: $link-border-color;
    }
  }
  border-bottom: 1px solid rgba($gray-02, 0.5);

  a {
    display: block;
    padding: 1.6rem 2.5rem;
  }
}

.UserMenu__signOut {
  padding: 1.6rem 2.5rem;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  width: 100%;
  text-align: left;
  background: $odc-black right 2.4rem center / 2.4rem no-repeat url('/icons/exit.svg');

  &--isMobile {
    @include font('p2-regular');
    color: $gray-01;
    background-color: $dark-blue;
    border-top: $button-border-color;
    border-bottom: $button-border-color;
    border-radius: 0;
  }
}
