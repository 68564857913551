@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/responsive';
@import '../styles/z-index';

.Notification {
  background-image: linear-gradient(93deg, $purple, $purple 52%, $odc-red), linear-gradient(to bottom, $purple, $purple);
  padding: 1.2rem 2.4rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: z-index(general-notification);

  .Notification__dim {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($odc-black, 0.7);
    top: 0;
    left: 0;
    z-index: z-index(general-notification-dim);
  }

  .Notification__message {
    @include font('p1-regular');
    @include screen-only-xs {
      max-width: none;
    }
    color: $odc-white;
    display: inline-block;
    max-width: calc(100% - 16rem - 1rem);
    word-break: break-word;
  }

  .Notification__button {
    @include font('p1-bold');
    @include screen-only-xs {
      float: none;
      display: block;
      width: 100%;
      margin-top: 3.6rem;
      padding: 1.2rem 0;
    }

    color: $purple;
    border-radius: 4px;
    background-color: $odc-white;
    padding: 0.4rem 0;
    width: 16rem;
    text-align: center;
    cursor: pointer;
    float: right;

    &:hover {
      opacity: 0.8;
    }
  }

  .Notification__cancelButton {
    @include screen-only-xs {
      flex: 1;
    }

    margin-right: 0.8rem;
    border: 1px solid $odc-white;
    color: $odc-white;
    background-color: transparent;
    padding: 0.3rem 0;
    width: 11.2rem;
  }
}
