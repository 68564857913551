@import '../styles/variables';
@import '../styles/fonts';

.SideEpisodeItem__itemTitle {
  @include font('p1-medium');
  color: $gray-01;
}

.SideEpisodeItem__itemSubTitle {
  @include font('p2-regular');
  color: $gray-02;
}
