@import '../styles/figures.scss';

.ItemImage {
  position: relative;
}

.ItemImage__ratio {
  @extend %backgroundTransition;
  overflow: hidden;
  height: 0;
  width: 100%;

  &.ItemImage__ratio--16to9 {
    padding-top: 57.5%;
  }
  &.ItemImage__ratio--3to4 {
    padding-top: 133.33333333%;
  }

  &--portrait {
    position: relative;
    background-repeat: repeat-x;
    opacity: 0.2;
    background-size: contain;

    & ~ div {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
