@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/responsive';

.SearchBarInput {
  @include font('p1-regular');
  display: flex;
  flex-direction: row;
  background-color: $odc-black;

  .SearchBarInput__input {
    color: $odc-white;
    font: inherit;
    flex: 1;
    border: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    padding: 1.6rem 0 1.6rem 4.8rem;
    background: $odc-black left 1.6rem center / 2.4rem no-repeat url('/icons/search-grey.svg');

    &:focus {
      background-image: url('/icons/search.svg');
    }

    &::placeholder {
      color: $gray-04;
    }

    &::-ms-clear {
      display: none;
    }

    @include screen-lg-lt {
      padding-left: 5.6rem;
      background-size: 3.2rem;
    }
  }
}

.SearchBarInput__clearButton {
  color: $gray-02;
  padding: 1.6rem 2rem;
  cursor: pointer;
}

.SearchBarInput__closeButton {
  @include buttonTitleWrapper('/icons/close-grey.svg', 5.6, 2.4);
  width: 5.6rem;
  height: 5.6rem;
  cursor: pointer;

  @include screen-lg-lt {
    @include buttonTitleWrapper('/icons/close-grey.svg', 5.6, 3.2);
  }
}
