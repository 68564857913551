@import '../../styles/variables';
@import '../../styles/responsive';
@import '../../styles/fonts';

.ErrorPage {
  position: relative;
  padding: 6.4rem 0 16rem;
  background: url('/images/img-bg-red.svg') no-repeat;
  background-size: cover;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 16rem;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), $odc-black);
  }
}

.ErrorPage__logo {
  display: block;
  margin: 0 auto;
  width: 16rem;
}

.ErrorPage__wrapper {
  margin-top: 4rem;
  color: $odc-white;
  text-align: center;
  @include screen-only-xs {
    padding: 0 2.4rem;
  }
}

.ErrorPage__image {
  display: block;
  margin: 0 auto;
  @include screen-only-xs {
    width: 16rem;
  }
}

.ErrorPage__title {
  @include font('heading2');
}

.ErrorPage__phrase {
  @include font('p1-regular');
  color: $gray-01;
  margin: 1.6rem auto 0;
  max-width: 48rem;
}

.ErrorPage__link {
  @include font('p1-medium');
  display: inline-block;
  margin-top: 4.8rem;
  padding: 1.2rem 0;
  width: 21.6rem;
  border: 1px solid $gray-02;
  border-radius: 8px;

  &:last-child {
    margin-left: 0.8rem;
    color: $odc-black;
    background-color: $odc-white;
    border-color: $odc-white;
  }

  @include screen-only-xs {
    width: calc((100% - 0.8rem) / 2);
  }
}

.ErrorPage__copyright {
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  padding-bottom: 1.6rem;
  color: $gray-01;
  text-align: center;
}
