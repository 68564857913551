@import '../styles/responsive';
@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/z-index';

.MobileSubCategorySelector {
  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index(overlay);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1.2rem 1.6rem;
  background-image: linear-gradient(to bottom, rgba($dark-blue, 0.9), rgba($odc-black, 0.9));
}

.MobileSubCategorySelector__header {
  @include font('p1-bold');
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $gray-02;

  &::after {
    content: '';
    position: absolute;
    bottom: -3.5rem;
    display: block;
    width: 100%;
    height: 1px;
    margin: 2rem 0;
    background-color: $gray-04;
  }

  @include screen-md-lt {
    @include font('p2-regular');
  }
}

.MobileSubCategorySelector__closeButton {
  width: 2.4rem;
  height: 2.4rem;
  outline: none;
  font-size: 0;
  cursor: pointer;

  @include buttonTitleWrapper('/icons/close-grey.svg', 0);
}

.MobileSubCategorySelector__categoryList {
  margin-top: 5rem;
  text-align: center;
}

.MobileSubCategorySelector__subCategory {
  @include font('heading4');
  padding: 1rem 0;
  color: $gray-02;

  &--selected {
    @include font('heading3');
    color: $odc-white;

    @include screen-md-lt {
      @include font('heading4');
    }
  }

  @include screen-md-lt {
    @include font('p1-regular');
  }
}
