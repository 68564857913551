@import '../styles/variables';
@import '../styles/utils';
@import '../styles/fonts';
@import '../styles/responsive';
@import '../styles/z-index';

$border-color: getAppliedOpacityColor($gray-01, 0.5);
$system-white-40: getAppliedOpacityColor($odc-white, 0.4);
$default-border-radius: 8px;

.LocaleSelector {
  @include screen-lg-lt {
    display: none;
  }

  display: inline-block;
  vertical-align: middle;
  text-align: right;
  position: relative;
  margin-left: 2.8rem;

  &::before {
    content: ' | ';
    position: absolute;
    top: 50%;
    left: -1.4rem;
    transform: translateY(-50%);
    color: $system-white-40;
  }
}

.LocaleSelector__hover {
  position: absolute;
  width: 10rem;
  height: 20rem;
  top: 0;
  transform: translateX(-50%);
  left: 50%;
}

.LocaleSelector__locale {
  @include font('p2-regular');
  position: relative;
  cursor: pointer;
  padding-left: 3.2rem;
  background: left center / 2rem no-repeat url('/icons/language.svg');

  &::after {
    content: '';
    width: 1rem;
    height: 1rem;
    position: absolute;
    background: center / 1.2rem no-repeat url('/icons/arrow-drop-down.svg');
    right: -1.2rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.LocaleSelector__selector {
  display: none;
  position: absolute;
  top: 3.6rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background-color: $odc-black;
  border: 1px solid $gray-02;
  border-radius: $default-border-radius;
  text-align: left;
  word-break: keep-all;

  &--visible {
    display: block;
    z-index: z-index(navbar-selector);
  }
}

.LocaleSelector__localeItem {
  @include font('p1-medium');
  cursor: pointer;
  border-bottom: 1px solid rgba($gray-02, 0.2);
  padding: 1.6rem 8.3rem 1.6rem 2.3rem;

  &:first-child {
    border-top-left-radius: $default-border-radius;
    border-top-right-radius: $default-border-radius;
  }
  &:last-child {
    border-bottom-left-radius: $default-border-radius;
    border-bottom-right-radius: $default-border-radius;
    border: 0;
  }
  &:hover {
    background-color: $border-color;
    color: $odc-white;
  }

  &--selected {
    background: $odc-black right 2.1rem center / 2.4rem no-repeat url('/icons/ico-check-grey.svg');
  }
}
