@import '../styles/responsive';
@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/z-index';

.NavBar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index(navbar);
  width: 100%;
  height: 7.3rem;
  border-bottom: 1px solid $dark-blue;
  background-color: $odc-black;
  color: $white;
  transition: 0.2s;

  &--transparent {
    border: none;
    background-color: transparent;
    background-image: linear-gradient(to top, rgba(17, 18, 18, 0) 5%, rgba(17, 18, 18, 0.9) 100%);
  }

  &--sticky {
    background-color: $dark-blue;
  }
}

.NavBar__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 152rem;
  margin: 0 auto;
  padding: 0 1.6rem;

  .Logo {
    order: 2;
    width: 13.3rem;
    margin: 0 auto;
    padding: 1.6rem 0;
  }

  @include screen-lg-gte {
    order: 1;
  }

  @include screen-lg-lt {
    padding: 0;
  }
}

.NavBar__logo {
  width: 13.3rem;
}

.NavBar__menuToggle {
  display: inline-block;
  vertical-align: middle;
  align-self: center;
  order: 1;
  width: 3.2rem;
  height: 3.2rem;
  outline: none;
  cursor: pointer;

  @include buttonTitleWrapper('/icons/menu.svg', 3.2);

  @include screen-lg-gte {
    display: none;
  }

  @include screen-lg-lt {
    width: 6.4rem;
    height: 7.2rem;

    @include buttonTitleWrapper('/icons/menu.svg', 7.2, 3.2);
  }
}

.NavBar__right {
  align-self: center;
  order: 3;
  text-align: right;

  &--hide {
    display: none;
  }

  @include screen-lg-gte {
    flex: 1;
  }

  @include screen-lg-lt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.4rem;
    height: 7.2rem;
  }
}

.NavBar__searchButton {
  vertical-align: middle;

  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;

  @include buttonTitleWrapper('/icons/search.svg', 2.4);

  @include screen-lg-lt {
    width: 6.4rem;
    height: 7.2rem;

    @include buttonTitleWrapper('/icons/search.svg', 7.2, 3.2);
  }
}
