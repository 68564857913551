@import './variables';

@mixin inputLabelHeightAnimation($height, $notFocusedSize: 0, $focusedSize: 0) {
  .InputWithErrorMessage__inputForm:not(.InputWithErrorMessage__inputForm--empty) + .InputWithErrorMessage__label {
    @if $focusedSize != 0 {
      font-size: #{$focusedSize}rem;
    }
    transform: translateY(-#{$height}rem);
  }

  .InputWithErrorMessage__inputForm:focus + .InputWithErrorMessage__label {
    @if $focusedSize != 0 {
      font-size: #{$focusedSize}rem;
    }
    transform: translateY(-#{$height}rem);
    transition: all 0.3s cubic-bezier(0.5, 0.115, 0.63, 0.845);
  }

  .InputWithErrorMessage__inputForm--empty:not(:focus) + .InputWithErrorMessage__label {
    @if $notFocusedSize != 0 {
      font-size: #{$notFocusedSize}rem;
    }
    transform: translateY(0);
    transition: all 0.3s cubic-bezier(0.5, 0.115, 0.63, 0.845);
  }
}

@mixin buttonTitleWrapper($imageUrl, $padding, $imageSize: 0) {
  @if $imageSize == 0 {
    background: center / cover no-repeat url($imageUrl);
  } @else {
    background: center / #{$imageSize}rem no-repeat url($imageUrl);
  }
  overflow: hidden;
  padding-top: #{$padding}rem;
}

@mixin episode-display-count($count, $margin: 0.8rem) {
  width: calc(100% / #{$count});
  margin-bottom: 2.4rem;
}
