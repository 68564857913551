@import './reset';
@import './variables';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
  font-size: 10px;
  background-color: $odc-black;
  font-family: 'Noto Sans SC', 'Noto Sans', Tahoma, Helvetica, Arial, 'Microsoft Yahei', '微软雅黑', STXihei, '华文细黑',
    sans-serif;
  font-weight: 400;
  min-height: 100%;
}
#root {
  min-height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.overlay {
  overflow-y: hidden;
}

#onesignal-bell-container {
  display: none;
}
