@import '../styles/variables';
@import '../styles/responsive';
@import '../styles/fonts';

.SideContents {
  grid-area: S;
  width: 33.2rem;
  min-height: 100vh;
  padding: 1.6rem;
  border-left: 1px solid $dark-blue;

  .SideContents__title {
    @include font('p1-medium');
    margin-top: 3.2rem;
    color: $gray-02;
  }

  .SideContents__loading {
    margin-top: 1.6rem;
  }

  .SideContents__content {
    overflow-y: auto;
  }

  .SideContents__itemTitle {
    @include font('p1-medium');
    color: $gray-01;
  }

  .SideContents__itemSubTitle {
    @include font('p2-regular');
    color: $gray-02;
  }

  @include screen-lg-lt {
    display: none;
  }
}
