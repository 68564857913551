@import './utils';

$heading1: (
  font-size: rem(2.5),
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$heading2: (
  font-size: rem(2),
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$heading3: (
  font-size: rem(1.5),
  font-weight: 500,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$heading4: (
  font-size: rem(1.125),
  font-weight: 500,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$heading5: (
  font-size: rem(1),
  font-weight: 500,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$p1-regular: (
  font-size: rem(1),
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$p1-medium: (
  font-size: rem(1),
  font-weight: 500,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$p1-bold: (
  font-size: rem(1),
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$p2-regular: (
  font-size: rem(0.875),
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$p3-regular: (
  font-size: rem(0.75),
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$p4-regular: (
  font-size: rem(0.625),
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$p4-medium: (
  font-size: rem(0.625),
  font-weight: 500,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$p4-bold: (
  font-size: rem(0.625),
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$badge: $p4-medium;

$label: (
  font-size: rem(0.875),
  font-weight: normal,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

$caption: (
  font-size: rem(0.688),
  font-weight: normal,
  font-style: normal,
  font-stretch: normal,
  line-height: normal,
  letter-spacing: normal,
);

// global tokens
$tokens: (
  heading1: $heading1,
  heading2: $heading2,
  heading3: $heading3,
  heading4: $heading4,
  heading5: $heading5,
  p1-regular: $p1-regular,
  p1-medium: $p1-medium,
  p1-bold: $p1-bold,
  p2-regular: $p2-regular,
  p3-regular: $p3-regular,
  p4-regular: $p4-regular,
  p4-medium: $p4-medium,
  p4-bold: $p4-bold,
  badge: $badge,
  label: $label,
  caption: $caption,
);

@mixin font($name) {
  $token: map-get($tokens, $name);

  @each $name, $value in $token {
    #{$name}: $value;
  }
}
