@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/responsive';

.MobileLocaleSelector__button {
  @include font('p2-regular');
  color: $gray-01;
  width: 100%;
  text-align: left;
  padding: 1.8rem 4.8rem;
  background: transparent left 2.4rem center/2rem 2rem no-repeat url('/icons/language.svg');
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid rgba($gray-02, 0.2);

  &::after {
    content: '';
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    right: 2.4rem;
    top: 1.8rem;
    background: center / cover no-repeat url('/icons/ico-arrow-down-white.svg');
    transition: transform 0.3s;
  }

  &--active::after {
    transform: rotate(180deg);
  }
}

.MobileLocaleSelector__links {
  @include font('p2-regular');
  position: relative;
  display: block;
  color: $gray-01;
  margin-top: 0;
  width: 100%;
}

.MobileLocaleSelector__localeItem {
  @include font('p2-regular');
  cursor: pointer;
  border-bottom: 1px solid rgba($gray-02, 0.2);
  padding: 1.6rem 2.3rem;
  color: rgba($gray-01, 0.4);

  &:hover {
    background-color: rgba($gray-01, 0.5);
    color: $gray-01;
  }

  &--selected {
    background: $odc-black right 2.1rem center / 2.4rem no-repeat url('/icons/ico-check-grey.svg');
    color: $gray-01;
  }
}
