@import '../styles/fonts';
@import '../styles/variables';

.SideProgramItem__title {
  @include font('p1-medium');
  color: $gray-01;
}

.SideProgramItem__meta {
  @include font('p3-regular');
  margin-top: 0.4rem;
  color: rgba($gray-02, 0.7);
}
